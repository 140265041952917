const MAPPABLE_BETTING_TYPES = [
    "player-total-assists",
    "player-total-rebounds",
    "player-total-3-point-field-goals",
    "player-total-points",
    "player-total-rushing-yards",
    "player-total-passing-touchdowns",
    "player-total-passing-yards",
    "player-total-receiving-yards"
];

export function mapPlayerToSpecifier(offerList) {

    offerList.forEach((offer) => {
        if (offer.sportData && MAPPABLE_BETTING_TYPES.includes(offer.sportData.bettingTypeAbrv)) {
            const playerName = offer.playerFirstName.trim() + " " + offer.playerLastName.trim();
            let teamName;

            if (offer.teamId) {
                const teams = new Map([
                    [offer.sportData.teamOneId, offer.sportData.teamOneName],
                    [offer.sportData.teamTwoId, offer.sportData.teamTwoName],
                ]);

                teamName = teams.get(offer.teamId)
            }

            const constructedPlayerName = `${playerName} ${teamName ? `(${teamName})` : ""}`;

            offer.specifier.player = constructedPlayerName;
            offer.playerId = null;
            offer.teamId = null;
        }
    })
}