import { reduce } from 'lodash';

import { getOrdinal } from './ordinals';

const validModifiers = ['+', '-', '!'];

/**
 * Parses specifier template string 
 * @param {string} specifierName specifier string
 * @param {{}} specifiers dynamic object based on specifier string
 * @param {{}|null} additionalData any additional data required
 */
export function parseSpecifier(specifierName: string, specifiers = null, additionalData = null) {
    // parseSegments = segments of specifier, parsedString = specifier after parsing
    let parsedDictionary = {
        parseSegments: {},
        parsedString: specifierName
    };

    let dataDict = {};

    if (specifiers != null) {
        dataDict = Object.assign({}, dataDict, specifiers);
    }

    if (additionalData != null) {
        dataDict = Object.assign({}, dataDict, additionalData);
    }

    if (specifiers == null && additionalData == null) {
        return parsedDictionary;
    }

    if (specifierName === '' || specifierName == null) {
        return parsedDictionary;
    }

    // optimization: using lodash reduce
    let indexes = reduce(specifierName, (acc, item, index) => {
        if (item === '{') {
            acc.open.push(index);
        }
        if (item === '}') {
            acc.close.push(index);
        }

        return acc;
    }, {
        open: [],
        close: []
    });

    // reverse indexes in case there are specifiers with nested brackets
    if (indexes.close[0] + 1 == indexes.close[1]) {
        indexes.close.reverse();
    }

    // unparsed segment names array
    let segmentsArray = [];
    for (let i = 0; i < indexes.open.length; i++) {
        // take segment name
        let tempSegmentName = specifierName.substring(indexes.open[i] + 1, indexes.close[i]);
        // check if segment name is wrapped in double {{}}
        if (tempSegmentName[0] == '{') {
            segmentsArray.push(tempSegmentName.slice(1, -1));
        } else {
            segmentsArray.push(tempSegmentName);
        }
    };

    // make object pairs and add them to the dictionary
    segmentsArray.forEach((item, idx) => {
        let itemName = '';
        // if item name starts with a sign, slice it, otherwise use as is
        if (getModifier(item) != null) {
            itemName = item.slice(1); // i.e. '!periodnr'
        } else {
            itemName = item; // i.e. 'hcp', exclusive case is $competitorN / %event so we can know if it starts with $ later
        }

        let itemValue = '';
        // if item name starts with -, ignore that - when getting value
        if (itemName.startsWith('-')) {
            itemValue = dataDict[itemName.substring(1)];
        } else if (itemName.startsWith('$') || itemName.startsWith('%')) {
            itemValue = dataDict[itemName.substring(1)]; // i.e. team one name
        } else {
            itemValue = dataDict[itemName]; // i.e. '2'
        }

        let itemSign = getModifier(item); // i.e. '!'
        let parsedItemValue = evaluateParsedValue(itemSign, itemName, itemValue); // i.e. 'Second'
        parsedDictionary.parseSegments[itemName] = {
            sign: itemSign,
            value: itemValue,
            parsedValue: parsedItemValue
        }

        // change specifier to parsed value; use starting and ending indexes array since there is no more precise way of telling is specifier in {} or in {{}}
        parsedDictionary.parsedString = parsedDictionary.parsedString.replace(specifierName.substring(indexes.open[idx], indexes.close[idx] + 1), parsedItemValue);
    });

    // returns parsed value depending on sign
    function evaluateParsedValue(sign, name, value) {
        let parsedString;
        switch (sign) {
            case '-':
                if (value.startsWith('-')) {
                    parsedString = value.substring(1);
                } else {
                    parsedString = '-' + value;
                }
                break;
            case '!':
                return getOrdinal(value); //numberOrdinalNumberTextDict[value];
            case '+':
            case '$':
            case '%':
            default:
                parsedString = value;
                break;
        }

        return parsedString;
    }

    // returns sign value depending on first char of unparsed segment name
    function getModifier(unparsedSegmentName) {
        const modifier = unparsedSegmentName[0];

        if (validModifiers.indexOf(modifier) !== -1) {
            return modifier;
        }

        return null;
    }

    return parsedDictionary;
}