export const BetSlipErrorCodes = {
    Unauthenticated: 401100,
    ValidationError: 400100,
    InvalidInputAmount: 400110,
    SlipEmpty: 400200,
    ServerError: 500110,
    Generic: 500100,
}

export const BetSlipStakeErrorCodes = [
    400017,
    400018,
    400019,
    400020,
    400024,
    400030,
    400032,
    400033,
    400036,
    400037,
    400038,
    400064
]