function gcd(a, b) {
    // Since there is a limited precision we need to limit the value.
    if (b < 0.0000001) return a;

    // Discard any fractions due to limitations in precision.
    return gcd(b, Math.floor(a % b));
}

/**
 * Converts decimal to fractional
 * @param {number} value decimal odd value
 * @returns {string} numerator/denominator
 */
export function convertDecimalToFractional(value) {
    if (!value) {
        return null;
    }

    // deduce 1 first
    value = (value - 1).toFixed(2);

    // convert to fraction
    for (var denominator = 1; (value * denominator) % 1 !== 0; denominator++);
    return `${value * denominator}/${denominator}`;
}