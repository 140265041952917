export function formatTip(value: number) {
    if (value <= 0) {
        return null;
    }
    else if (value > 0 && value < 100) {
        return value.toFixed(2);
    }
    else {
        return value.toFixed(0);
    }
}

export function toTwoDecimals(value: number) {
    return Math.round(value * 1e2) / 1e2;
}

export function prepareTipValue(value: number) {
    return formatTip(value);
}