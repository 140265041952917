(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@gp/common"), require("@gp/models"), require("lodash"), require("mobx"));
	else if(typeof define === 'function' && define.amd)
		define(["@gp/common", "@gp/models", "lodash", "mobx"], factory);
	else if(typeof exports === 'object')
		exports["gp"] = factory(require("@gp/common"), require("@gp/models"), require("lodash"), require("mobx"));
	else
		root["gp"] = factory(root["@gp/common"], root["@gp/models"], root["lodash"], root["mobx"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__532__, __WEBPACK_EXTERNAL_MODULE__879__, __WEBPACK_EXTERNAL_MODULE__804__, __WEBPACK_EXTERNAL_MODULE__965__) {
return 