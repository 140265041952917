 export function getOrdinal(number: number) {
    const ten = number % 10;
    const hundred = number % 100;

    let ext = ".";

    if (ten == 1 && hundred != 11) {
        ext = ".";
    }
    if (ten == 2 && hundred != 12) {
        ext = ".";
    }
    if (ten == 3 && hundred != 13) {
        ext = ".";
    }

    return number + ext;
}