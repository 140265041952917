export function mapDisplayValues(offerList, display) {
    offerList.forEach((offer) => {

        if (display == "european") {
            if (offer.sportData) {
                offer.displayValues = {
                    result: offer.sportData.result,
                    teamOneName: offer.sportData.teamOneName,
                    teamTwoName: offer.sportData.teamTwoName,
                    teamOneGender: offer.sportData.teamOneGender,
                    teamTwoGender: offer.sportData.teamTwoGender,
                    teamOneId: offer.sportData.teamOneId,
                    teamTwoId: offer.sportData.teamTwoId,
                }
            }

            offer.displayValues.currentResult = offer.currentResult;

            return;
        };

        if (offer.sportData) {
            const currentResult = offer.currentResult ? JSON.parse(offer.currentResult) : null;
            const result = offer.sportData.result ? JSON.parse(offer.sportData.result) : null;

            updateResultModel(currentResult);
            updateResultModel(result);

            offer.displayValues = {
                currentResult: currentResult && JSON.stringify(currentResult),
                result: result && JSON.stringify(result),
                eventName: offer.sportData.eventName,
                teamOneName: offer.sportData.teamTwoName,
                teamTwoName: offer.sportData.teamOneName,
                teamOneGender: offer.sportData.teamTwoGender,
                teamTwoGender: offer.sportData.teamOneGender,
                teamOneId: offer.sportData.teamTwoId,
                teamTwoId: offer.sportData.teamOneId,
            }
        }
    })
}

function updateResultModel(result) {
    if (
        result == null ||
        result == "" ||
        (typeof result == "object" && Object.keys(result).length < 1)
    ) {
        return;
    }

    const switchFunction = (score) => {
        if (score.away && score.home) {
            const away = score.away;
            const home = score.home;

            score.home = away;
            score.away = home;
            return;
        }

        if (score.away) {
            score.home = score.away;
            delete score.away;
            return;
        }

        if (score.home) {
            score.away = score.home;
            delete score.home;
            return;
        }
    }

    if (result.scorePerPeriod && result.scorePerPeriod.length > 0) {
        result.scorePerPeriod.forEach(period => switchFunction(period));
    }

    if (result.currentGameScore) {
        switchFunction(result.currentGameScore);
    }

    if (result.tiebreakScore) {
        switchFunction(result.tiebreakScore);
    }

    if (result.currentScore) {
        switchFunction(result.currentScore);
    }

    if (result.serve) {
        if (result.serve == "away") {
            result.serve = "home";
        } else {
            result.serve = "away";
        }
    }

    const homeStats = result.homeTeamTotalStatistics;
    const awayStats = result.awayTeamTotalStatistics;

    if (homeStats && awayStats) {
        result.homeTeamTotalStatistics = awayStats;
        result.awayTeamTotalStatistics = homeStats;
    }
    else if (homeStats) {
        result.awayTeamTotalStatistics = homeStats;
        delete result.homeTeamTotalStatistics;
    }
    else if (awayStats) {
        result.homeTeamTotalStatistics = awayStats;
        delete result.awayTeamTotalStatistics;
    }
}