type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>
import { observable, action, computed, decorate, runInAction, autorun, toJS } from 'mobx';
import { isEmpty, uniq, xor, max, uniqWith, each, groupBy } from 'lodash';
import {
    OfferSubscriptionResponse,
    BetSlipTypes,
    BetSlipPaymentType,
    BetSlipOffer,
    BetSlipResponse,
    BetSlip,
    BetSlipValidationError,
    BetSlipProcessingError,
    BetSlipUserValidationOnBetSlipSubmitError,
} from '@gp/models'
import { ISubscriptionRequest } from "@gp/hub";

import { BetSlipActions } from './BetSlipActions';
import { BetSlipBetType } from './BetSlipBetType';
import { BetSlipErrorCodes } from './BetSlipErrorCodes';
import { BetSlipErrorMessages } from './BetSlipErrorMessages';
import { ActionController, ActionType } from '@gp/common';

import { OddTypeStore, mapPlayerToSpecifier, mapDisplayValues } from './offer';
import {
    IBetSlipStoreConfig,
    IBetSlipStoreInitialState,
    IBetSlipService,
    ICombination,
    IBaseTip,
    IBetSlipRequest,
    INotificationProviderError,
    INotificationProviderMappedError
} from './types';
import { getIndicator } from './helpers';


const SGuid = {
    empty: '0000000000000000000000'
}

/** Use BetSlip constructor form gp-models. */
export function createEmptySlip(): BetSlip {
    // @ts-expect-error
    return {
        maxGain: 0,
        minGain: 0,
        maxCoefficient: 0,
        bankOfferCount: 0,
        betSlipOffers: [],
    };
}

const DefaultConfig: IBetSlipStoreConfig = {
    isUserAuthenticated: () => false,
    hub: null,
    oddTypeStore: new OddTypeStore(),
    updateBalance: null,
    oneClickBetShowDuration: 2000,
    slipIndicatorDuration: 2900,
    isReuseEnabled: false,
    throttle: 4,
    onReset: () => { },
    compress: false,
    setServerAvailability: () => { },
    offerDisplay: "european",
    actionControllerOptions: {
        actionThrottle: 700,
        minorActionDelay: 4000,
    }
};

// default state for bet slip
const defaultState: IBetSlipStoreInitialState = {
    totalStake: 2,
    payment: 2,
    stakePerCombination: 2,
    paymentPerCombination: 2,
    gain: 0,
    paymentType: BetSlipPaymentType.TOTAL,
    betSlipType: BetSlipTypes.COMBINATION,
}

const stakeErrorCodes = [400017, 400018, 400019, 400020, 400024, 400030, 400032, 400033, 400036, 400037, 400038, 400064];

function createMemory(slipStore: BetSlipStore) {
    return {
        totalStake: slipStore.totalStake,
        stakePerCombination: slipStore.stakePerCombination,
        paymentPerCombination: slipStore.paymentPerCombination,
        gain: slipStore.gain,
        payment: slipStore.payment,
        maxGain: slipStore.maxGain,
        minGain: slipStore.minGain,
        gainBonusPercentage: slipStore.gainBonusPercentage,
        gainBonusAmount: slipStore.gainBonusAmount,
        gainTaxPercentage: slipStore.gainTaxPercentage,
        gainTaxAmount: slipStore.gainTaxAmount,
        handlingFeePercentage: slipStore.handlingFeePercentage,
        handlingFeeAmount: slipStore.handlingFeeAmount,
        combinationBettingOffersCount: slipStore.combinationBettingOffersCount
    };
}

function mapRequestOffer(o) {
    return {
        id: o.id,
        eventId: o.eventId,
        value: o.value,
        isBank: o.isBank
    };
}

function mapCurrentOffer(o) {
    return {
        id: o.bettingOfferId,
        eventId: o.eventId,
        value: o.value,
        isBank: o.isBank
    };
}

// Not used on web
export const BetSlipTypeStorageKey = 'type';
export const BetSlipStorageKey = 'slip';
export const BetSlipSystemCombinations = 'system';
export const BetSlipSelectedCombinations = 'ssystem';
export const BetSlipBankedEvents = 'system-bank';
export const BetSlipAcceptOddsChange = 'betslip-accept-odds-change';
export const BetSlipTimeoutKey = 'to';

// Replace is selected tip array with a map
// Implement is tip excluded as set or map
class BetSlipStore {
    //#region parameters

    betSlipService: IBetSlipService;
    config: IBetSlipStoreConfig;
    initialState: IBetSlipStoreInitialState;
    slip: BetSlip = new BetSlip();
    abortController: AbortController;
    /** In system slip, available combination. */
    availableCombinations: ICombination[];
    /** User selected system combinations. */
    selectedCombinations: number[];
    error: INotificationProviderError;
    /** Tips clicked in one click bet. */
    _oneClickTips: string[];
    betSlipType: BetSlipTypes;
    totalStake: number;
    /**
     * If true, total stake is applied and slip processing is triggered
     * This is set to true if total stake changes
     */
    shouldApplyTotalStake: boolean;
    /**
     * Stake per played combination
     * @deprecated - use paymentPerCombination
     */
    stakePerCombination: number;
    /** Payment per played combination. */
    paymentPerCombination: number;
    /** Slip payment. */
    payment: number;
    /**
     * If true, stake per combination is applied and slip processing is triggered
     * This is set to true if stake per combination changes
     * @deprecated
     */
    shouldApplyStakePerCombination: boolean;
    /**
     * If true, payment per combination is applied and slip processing is triggered
     * This is set to true if stake per combination changes
     */
    shouldApplyPaymentPerCombination: boolean;
    gain: number;
    /**
     * If true, max gain is applied and slip processing is triggered
     * This is set to true if max gain changes
     */
    shouldApplyGain: boolean;

    /** Number of combinations. */
    combinations: number;
    paymentType: BetSlipPaymentType;
    /** We should block all attempts to add another offer in the bet slip, while another is processing. */
    isBettingBlocked: boolean;
    /** Accept odds changes. */
    acceptOddsChanges: boolean;
    /** List of banked events. */
    bankEvents: string[];
    /** Determines bet slip betting state - is it normal betting or one click betting. */
    betSlipBetType: BetSlipBetType;
    /** One click bet amount. Applicable only if one click bet is active. */
    oneClickBetAmount: number;
    /** Indicates if the slip is being submitted. */
    isSubmitting: boolean;
    /** Indicates if the slip is being processed. */
    isProcessing: boolean;
    /** Number of betting offer combinations per event (multi way). */
    combinationBettingOffersCount: number;
    /** Handling fee percentage. */
    handlingFeePercentage: number;
    /**
     * Handling fee amount. Equals to totalStake * handlingFeePercentage (calculated on backend!)
     * @see handlingFeePercentage
     */
    handlingFeeAmount: number;
    maxGain: number;
    minGain: number;
    gainBonusPercentage: number;
    /**
     * Gain bonus amount. Equals to gain * gainBonusPercentage (calculated on backend!)
     * @see gainBonusPercentage
     */
    gainBonusAmount: number;
    gainTaxPercentage: number;

    /**
     * Gain tax amount
     * @see gainTaxPercentage
     */
    gainTaxAmount: number;
    /** Flag for turning on and off Reuse bet slip functionality. */
    isReuseOn: boolean;
    /** Flag for tracking if values in slip are manually changed. */
    isChangingSlipValues: boolean;
    /** Flag for tracking if bet slip is submitted. */
    submitted: boolean;
    /** Live slip timeout, consists of time in seconds and level on which timeout is set. */
    submitDelay: number;

    /** Used to store version of offer received from server. New version must have greater value, if not, they should be discarded. */
    betSlipSyncVersion: number;
    /** Number of played events in the bet slip. */
    eventCount: number;
    /** Used to bypass all checks that prevent slip mapping. This is mainly used when removing something from bet slip. False by default. */
    @observable private _forceSync: boolean;
    /** Clicked user tips.  */
    clickedTips: any[];

    /**
     * @type {{totalStake: number, stakePerCombination: number, paymentPerCombination: number, gain: number, combinationBettingOffersCount: number}}
     * @description Used for memoizing applied input values and validation
     */
    _memory: ReturnType<typeof createMemory>;
    /** Internal error debounce id. */
    private _errorDebounceId: NodeJS.Timeout;
    /** last try of submitting slip. */
    offersFromLustSubmit: unknown;
    onClickInProgress: boolean;
    actionController: ActionController;
    runAutoRunDisposer: any;
    subscription: any;
    isRequestForPaymentEnabled: any;
    excludedEvents: any;

    //#endregion parameters

    constructor(
        betSlipService: IBetSlipService,
        config: Partial<IBetSlipStoreConfig>,
        initialState: Partial<IBetSlipStoreInitialState>
    ) {
        this.betSlipService = betSlipService;
        this.config = Object.assign({}, DefaultConfig, config);
        this.initialState = Object.assign({}, defaultState, initialState);

        this.slip = new BetSlip();
        this.onInitialize();
        this.abortController = new AbortController();


        //#region  initial state

        this.availableCombinations = [];
        this.selectedCombinations = [];
        this.error = null;
        this._oneClickTips = [];
        this.betSlipType = this.initialState.betSlipType;
        // --- Payment inputs ---
        this.totalStake = this.initialState.totalStake;
        this.shouldApplyTotalStake = false;
        this.stakePerCombination = this.initialState.stakePerCombination;
        this.paymentPerCombination = this.initialState.paymentPerCombination;
        this.payment = this.initialState.totalStake;
        this.shouldApplyStakePerCombination = false;
        this.shouldApplyPaymentPerCombination = false;
        this.gain = this.initialState.gain;
        this.shouldApplyGain = false;
        this.combinations = 0;
        this.paymentType = this.initialState.paymentType;
        this.isBettingBlocked = false;
        this.acceptOddsChanges = false;
        this.bankEvents = [];
        this.betSlipBetType = BetSlipBetType.Normal;
        this.oneClickBetAmount = 2;
        this.isSubmitting = false;
        this.isProcessing = false;
        this.combinationBettingOffersCount = 0;
        this.handlingFeePercentage = 0;
        this.handlingFeeAmount = 0;
        this.maxGain = 0;
        this.minGain = 0;
        this.gainBonusPercentage = 0;
        this.gainBonusAmount = 0
        this.gainTaxPercentage = 0;
        this.gainTaxAmount = 0;
        this.isReuseOn = false;
        this.isChangingSlipValues = false;
        this.submitted = false;
        this.submitDelay = null;
        this.betSlipSyncVersion = 0;
        this.eventCount = 0;
        this._forceSync = false;

        //#endregion initial state
        this.clickedTips = [];
        this._memory = createMemory(this);
        this._errorDebounceId;
        this.offersFromLustSubmit = {};
        this.onClickInProgress = false;

        this.actionController = new ActionController({
            functionCall: this.processBetSlip,
            ...this.config.actionControllerOptions,
        });

        this.isInBetSlip = this.isInBetSlip.bind(this);
        this.isTipClicked = this.isTipClicked.bind(this);
        this.isInOneClickBet = this.isInOneClickBet.bind(this);
    }

    onInitialize() {
        this.disposeRunAutoRunDisposer();
        if (this.hub) {
            this.runAutoRunDisposer = autorun(this.run);
        }
    }

    //#region  getters 

    /** Array of tips used only for processing (no excluded tips). */
    get currentSlipOffers() {
        return this.slip.betSlipOffers.filter((o) => !o.isExcluded).map(o => ({ ...mapCurrentOffer(o), isBank: this.bankEvents.includes(o.eventId) }));
    }

    /**  Number of clicked tips. */
    @computed private get offerSize(): number {
        return this.slip.betSlipOffers.length;
    }

    /** In system slip gets count of banked offers. */
    get bankOfferCount() {
        return this.slip.bankOfferCount || 0;
    }

    /**  Array of offers in the bet slip returned from the server. */
    get slipOffer() {
        if (!this.isSlipEmpty) {
            return this.slip.betSlipOffers.map(o => {
                return {
                    ...o,
                    displayValue: this.config.oddTypeStore.mapFn(o.value)
                }
            });
        }

        return [];
    }

    /** @deprecated */
    @computed private get hub() {
        return this.config.hub;
    }

    @computed private get displaySlipOffer() {
        const clickedOfferIds = new Set(this.clickedTips.map(x => x.id));
        return this.slipOffer.filter(offer => clickedOfferIds.has(offer.bettingOfferId));
    }

    @computed get isSlipEmpty(): boolean {
        return this.slip.betSlipOffers.length == 0;
    }

    get isProcessingSlipEmpty(): boolean {
        return this.currentSlipOffers.length === 0;
    }

    get slipSize(): number {
        return this.slipOffer.length;
    }

    /** SlipEventEntries} Slip offer grouped by event. */
    get slipEventEntries() {
        return this.slipOffer.reduce<
            { [eventId: string]: typeof BetSlipStore.prototype.slipOffer }
        >((acc, i) => {
            if (!acc[i.eventId]) {
                acc[i.eventId] = [];
            }

            acc[i.eventId].push(i);

            return acc;
        }, {});
    }

    /** Not used on web */
    get displaySlipEventEntries() {
        mapDisplayValues(this.displaySlipOffer, this.config.offerDisplay);
        return this.displaySlipOffer.reduce<{
            [eventId: string]: typeof BetSlipStore.prototype.displaySlipOffer
        }>((acc, i) => {
            if (!acc[i.eventId]) {
                acc[i.eventId] = [];
            }

            acc[i.eventId].push(i);

            return acc;
        }, {});
    }

    /** Max coefficient on the slip that was returned from the server. */
    get slipMaxCoefficient(): number {
        if (!this.isSlipEmpty && !this.isProcessingSlipEmpty) {
            return this.slip.maxCoefficient;
        }
        return 1;
    }

    /** @deprecated*/
    get offers() {
        return this.slip.betSlipOffers;
    }

    /** Max gain on the slip that was returned from the server. */
    get slipMaxGain(): number {
        if (!this.isSlipEmpty && !this.isProcessingSlipEmpty) {
            return this.slip.maxGain;
        }

        return (this.totalStake - this.handlingFeeAmount);
    }

    /**
     * Not used on web
     * Used for formatting selected combinations in system slip for system slip validation & submission.
     * Returns selected combinations joined by '+'. 
     * @example
     * // returns "1+2"
     * selectedCombinations = [1, 2];
     */
    get formattedCombinations(): string | null {
        if (this.selectedCombinations.length > 0) {
            return this.selectedCombinations.join('+');
        }

        return null;
    }

    /** Gets slip payment amount depending on the payment type and bet type. */
    @computed private get slipPaymentAmount(): number {
        if (this.isOneClickBetActive) {
            return this.oneClickBetAmount;
        }

        switch (this.paymentType) {
            case BetSlipPaymentType.MAX_GAIN:
                return this.gain;
            case BetSlipPaymentType.TOTAL:
                return this.payment;
            case BetSlipPaymentType.PER_COMBINATION:
                return this.paymentPerCombination;
            default:
                return this.payment;
        }
    }

    /**
     * Used on web
     * True if one click bet is active, otherwise false.
     */
    get isOneClickBetActive(): boolean {
        return this.betSlipBetType === BetSlipBetType.OneClickBet;
    }

    /**
     * Used on web
     * True if there are errors on the slip. Including validation errors.
     */
    get hasErrors(): boolean {
        return !isEmpty(this.error);
    }

    /**
     * Used on web
     * True if handling fee percentage is greater than 0
     */
    get isHandlingFeeActive(): boolean {
        return this.handlingFeePercentage > 0;
    }

    /** Clicked tips to js array,  */
    @computed private get clickedTipsRequestModel() {
        return toJS(this.clickedTips.filter(ct => !ct.isExcluded));
    }

    @computed private get isSubmitDisabled() {
        return this.actionController.actionInProgress;
    }

    //#endregion getters

    /** Initialize offer update. */
    private run = () => {
        if (!this.hub.isStarted) return;

        const bettingOfferKeyIds: string[] = uniq(this.slip.betSlipOffers.map(o => o.bettingOfferKeyId));

        // subscription exists and all the keys are the same
        if (this.subscription != null && xor(bettingOfferKeyIds, this.subscription.keys).length === 0) {
            return;
        }

        const eventIds: string[] = uniq(this.slip.betSlipOffers.map(o => o.eventId));

        if (bettingOfferKeyIds.length === 0 && eventIds.length === 0) {
            // no events or offers, unsubscribe!
            if (this.subscription) {
                this.subscription.offerSubscription.unsubscribe();
                this.subscription = null;
            }

            return;
        }

        const subscriptionRequest: ISubscriptionRequest = {
            subscriptionId: 'bet-slip',
            channels: [
                {
                    name: 'event',
                    filter: {
                        id: {
                            eq: eventIds
                        }
                    }
                },
                {
                    name: 'betOffer',
                    filter: {
                        id: {
                            eq: bettingOfferKeyIds
                        }
                    }
                }
            ],
            throttle: 3,
            compress: this.config.compress,
        };

        this.subscription = {
            events: eventIds,
            keys: bettingOfferKeyIds,
            offerSubscription: null
        };

        this.subscription.offerSubscription = this.hub.getOfferSubscription(subscriptionRequest).subscribe(response => {
            this.processMessage(response);
        });
    }
    @action.bound
    private checkPaymentType = (tip: IBaseTip, isRemove = false) => {
        let offersComposition = this.clickedTips.map(o => {
            return {
                eventId: o.eventId,
                bettingOfferId: o.id
            }
        });

        if (isRemove) {
            offersComposition = offersComposition
                .filter(item => item.bettingOfferId !== tip.id);
        } else {
            offersComposition.push({
                eventId: tip.eventId,
                bettingOfferId: tip.id
            });
        }

        let slipContainsMoreCombinations = offersComposition
            .some((element, index) => {
                return offersComposition
                    .findIndex(item => item.eventId === element.eventId) !== index;
            }) || this.selectedCombinations.length > 0;

        if (slipContainsMoreCombinations) {
            this.paymentType = BetSlipPaymentType.PER_COMBINATION;
        } else {
            if (this.paymentType !== BetSlipPaymentType.TOTAL) {
                this.paymentType = BetSlipPaymentType.TOTAL;
                this.payment = this.paymentPerCombination;
            }
        }
    }

    @action.bound
    private processMessage(response: OfferSubscriptionResponse) {
        const {
            offerChanges,
            startingVersion,
            version
        } = response;

        // Update result and time for offers
        if (response.offerChanges?.updates != null) {
            for (const update of response.offerChanges.updates) {
                const updatedOffer = this.slip.betSlipOffers.find(o => o.eventId === update.eventId);
                if (updatedOffer == null) continue;

                updatedOffer.eventResultData = update.event.result;
                updatedOffer.matchTime = update.event.matchTime;
            }
        }

        if (startingVersion === 0) {
            this.betSlipSyncVersion = version;

            if (!response.offerChanges || !response.offerChanges.updates) {
                // we got empty offer so all offer should be locked
                this.handleProcessBetSlip({
                    betSlipRequestData: () => this.clickedTipsRequestModel,
                    type: ActionType.MINOR_ACTION
                });
                return;
            }

        }

        if (startingVersion !== 0 && version <= this.betSlipSyncVersion) {
            // this is old offer, discard it
            return;
        }

        if (!offerChanges) {
            // no offer changes, this shouldn't happen tho
            return;
        }

        this.betSlipSyncVersion = version;

        /**
         * We need to sync ONLY when offer changes (either update or delete)
         * Possible changes are: lock, remove, quota change
         * If event changes we do nothing
         */
        let sync = this.slip.betSlipOffers.some(eo => {
            if (offerChanges.deletes) {
                // check if current offer exists in deletes
                const delEvent = offerChanges.deletes.find(del => eo.eventId === del.id);
                if (delEvent != null) return true;
            }

            if (offerChanges.updates) {
                // check if current offer exists in updates
                const updEvent = offerChanges.updates.find(upd => eo.eventId === upd.eventId);
                if (updEvent == null) return false;

                // we need to sync only if key was previously deleted and it now has offer
                if (
                    updEvent.offers != null &&
                    updEvent.offers.updates != null &&
                    !!eo.isLocked != !!updEvent.event.isLocked
                ) {
                    return true;
                }

                if (updEvent.offers) {
                    // event offer keys deletes/updates
                    if (updEvent.offers.deletes != null) {
                        const delKey = updEvent.offers.deletes.find(del => !eo.isDeleted && eo.bettingOfferKeyId === del.id);
                        if (delKey != null) return true;
                    }

                    if (updEvent.offers.updates != null) {
                        const updKey = updEvent.offers.updates.find(upd => eo.bettingOfferKeyId === upd.keyId);
                        if (updKey == null) return false;

                        if (updKey.offers) {
                            // key offers deletes/updates
                            if (updKey.offers.deletes) {
                                const delOffer = updKey.offers.deletes.find(del => !eo.isDeleted && eo.bettingOfferId === del.id);
                                if (delOffer != null) return true;
                            }

                            if (updKey.offers.updates) {
                                // update current offer
                                const updOffer = updKey.offers.updates.find(upd => eo.bettingOfferId === upd.id);

                                // current offer is not found in updated offers, this means it did not change - just continue
                                if (updOffer == null) return false;
                                let indicator = getIndicator(eo.value, updOffer.value);

                                if (indicator) return true;
                            }
                        }
                    }
                }
            }
        });

        if (sync) {
            this.handleProcessBetSlip({ betSlipRequestData: () => this.clickedTipsRequestModel, type: ActionType.MINOR_ACTION });
        }

    }

    public async removeInactiveOffers() {
        if (this.slip.betSlipOffers.length <= 0) return;

        const activeOffers = this.slip.betSlipOffers.filter(
            o => (
                !o.isEventLocked &&
                !o.isKeyLocked &&
                !o.isOfferLocked &&
                !o.isDeleted
            ));


        if (activeOffers.length <= 0) {
            this.reset();
            return;
        }

        this.clickedTips = this.clickedTips.filter((clickedTip) => {
            return activeOffers.some((activeOffer) => activeOffer.bettingOfferId === clickedTip.id)
        })

        // this is used to remove invalid combinations if you offers are removed
        if (this.selectedCombinations.length > 0) {
            this.selectedCombinations = this.selectedCombinations
                .filter(c => c <= activeOffers.length);
        }

        try {
            this.forceSync();
            await this.handleProcessBetSlip({
                betSlipRequestData: activeOffers.map(mapCurrentOffer)
            });
        } catch (error) {
            console.error(error);
        }
    }

    private disposeHubSubscription() {
        if (this.subscription) {
            this.subscription.offerSubscription.unsubscribe();
        }
    }

    //#region actions

    /**
     * Used on web
     * Used reset and init slip based on response from other source
     * @param {Object} betSlip - existing bet slip
     * @param {Object} options - use existing slip options
     * @example 
     * betSlip.useExistingSlip(mySlip);
     */
    async useExistingSlip(betSlip, options) {
        const { useValidOffersOnly = false, acceptOddsChanges = true, paymentType = BetSlipPaymentType.TOTAL } = options || {};

        this.reset();

        const offers = betSlip.betSlipOffers.map(mapCurrentOffer);

        if (betSlip.betSlipType) {
            this.betSlipType = betSlip.betSlipType.name.toLowerCase();
        }

        this.bankEvents = uniq(offers.filter(o => o.isBank).map(o => o.eventId));
        this.selectedCombinations = betSlip.system ? betSlip.system.split("+").map(s => parseInt(s, 10)) : [];

        await this.handleProcessBetSlip({
            betSlipRequestData: {
                acceptOddsChanges: acceptOddsChanges,
                bettingAccountTypeId: null,
                betSlipsFromRequest: [{
                    payment: betSlip.payment,
                    paymentType: paymentType,
                    type: this.betSlipType,
                    system: betSlip.system,
                    offers: offers,
                }]
            },
            action: BetSlipActions.Validate,
            options: {
                removeInactiveOffers: useValidOffersOnly
            },
            isUse: true
        });
    }

    /**
     * Use on web
     * Used to change total stake amount. Sets [payment type]{@link BetSlipStore#paymentType} to BetSlipPaymentTypes.Total
     * @param {number} value new value
     * @example 
     * <input type="number" onChange={e => onTotalStakeApply(e.target.value)} />
     */
    onTotalStakeChange(value, isIndirectChange = false) {
        if (value == null) {
            return;
        }

        // if (this.totalStake == value) {
        if (this.payment == value) {
            return;
        }

        if (!isIndirectChange) {
            this.isChangingSlipValues = true;
        }

        this.totalStake = value;
        this.payment = value;

        this.paymentType = BetSlipPaymentType.TOTAL;

        this.shouldApplyTotalStake = true;
    }

    /**
     * Used on web
     * Applies total stake value. Internally calls bet slip processing.
     * @example 
     * <input type="number" onBlur={e => onTotalStakeApply()} />
     */
    async onTotalStakeApply(force = false) {
        if (this.shouldApplyTotalStake) {
            const type = force ? ActionType.IMMEDIATE_ACTION : ActionType.USER_ACTION;
            await this.handleProcessBetSlip({ betSlipRequestData: () => this.clickedTipsRequestModel, type });

            this.isChangingSlipValues = false;

            runInAction(() => {
                this.shouldApplyTotalStake = false;
            });
        }
    }

    /**
     * Used on web
     * Used to change max gain amount. Sets [payment type]{@link BetSlipStore#paymentType} to BetSlipPaymentTypes.MaxGain
     * @param {number} value new value
     * @example 
     * <input type="number" onChange={e => onGainChange(e.target.value)} />
     */
    onGainChange(value) {
        if (!value) {
            return;
        }

        if (this.gain == value) {
            return;
        }

        this.isChangingSlipValues = true;

        this.gain = value;
        this.paymentType = BetSlipPaymentType.MAX_GAIN;

        this.shouldApplyGain = true;
    }

    /**
     * Used on web
     * Applies max gain value. Internally calls bet slip processing.
     * @example 
     * <input type="number" onBlur={e => onGainApply()} />
     */
    async onGainApply() {
        if (this.shouldApplyGain) {
            await this.handleProcessBetSlip({ betSlipRequestData: () => this.clickedTipsRequestModel, type: ActionType.USER_ACTION });
            this.isChangingSlipValues = false;

            runInAction(() => {
                this.shouldApplyGain = false;
            });
        }
    }

    /**
     * Not used on web
     * Used to change stake per combination amount. Sets [payment type]{@link BetSlipStore#paymentType} to BetSlipPaymentTypes.PerCombination
     * @deprecated 
     * @see onPaymentPerCombinationChange - use this instead
     * @example 
     * <input type="number" onChange={e => onStakePerCombinationChange(e.target.value)} />
     */
    onStakePerCombinationChange(value: number) {
        if (!value) {
            return;
        }

        if (this.stakePerCombination == value) {
            return;
        }

        this.stakePerCombination = value;
        this.paymentType = BetSlipPaymentType.PER_COMBINATION;

        this.shouldApplyStakePerCombination = true;
    }

    /**
     * Used on web
     * Used to change payment per combination amount. Sets [payment type]{@link BetSlipStore#paymentType} to BetSlipPaymentTypes.PerCombination
     * @param {number} value new value
     */
    onPaymentPerCombinationChange(value, isIndirectChange = false) {
        if (value == null) {
            return;
        }

        if (this.paymentPerCombination == value && value !== 0) {
            return;
        }

        if (!isIndirectChange) {
            this.isChangingSlipValues = true;
        }

        if (value === 0) {
            this.payment = 0;
        }

        this.paymentPerCombination = value;
        this.paymentType = BetSlipPaymentType.PER_COMBINATION;

        this.shouldApplyPaymentPerCombination = true;
    }

    /**
     * Not used on web
     * Applies stake per combination value. Internally calls bet slip processing.
     * @deprecated
     * @see onPaymentPerCombinationApply
     */
    async onStakePerCombinationApply() {
        if (this.shouldApplyStakePerCombination) {
            await this.handleProcessBetSlip({
                betSlipRequestData: () => this.clickedTipsRequestModel,
                type: ActionType.USER_ACTION
            });

            runInAction(() => {
                this.shouldApplyStakePerCombination = false;
            })
        }
    }

    /**
     * Used on web
     * Applies payment per combination value. Internally calls bet slip processing.
     * @example 
     * <input type="number" onBlur={e => onPaymentPerCombinationApply()} />
     */
    async onPaymentPerCombinationApply(force = false) {
        if (this.shouldApplyPaymentPerCombination) {
            const type = force ? ActionType.IMMEDIATE_ACTION : ActionType.USER_ACTION;
            await this.handleProcessBetSlip({ betSlipRequestData: () => this.clickedTipsRequestModel, type });

            this.isChangingSlipValues = false;

            runInAction(() => {
                this.shouldApplyPaymentPerCombination = false;
            })
        }
    }

    /**
     * Used on web
     * On change for one click bet amount.
     * @param {number} value new value
     */
    onOneClickBetAmountChange(value) {
        // TODO: we are not sure what is minimal amount for one click bet
        this.oneClickBetAmount = value;
    }

    /**
     * Used on web
     * Toggles [accept odds changes]{@link BetSlipStore#acceptOddsChanges}
     */
    onToggleAcceptOddsChangesChange() {
        this.acceptOddsChanges = !this.acceptOddsChanges;
    }

    /**
     * Not used on web
     * Validates input value based on key. If value is less than 1 it
     * applies last valid value
     * 
     * Error message is in format for translation.
     * Error message: BET_SLIP.ERRORS.VALUE_LESS_THAN_ONE
     * 
     * @return Returns true if amount is valid
     */
    validateAmount(key: keyof BetSlipStore['_memory']): boolean {
        this.resetError();

        const value = this[key];

        if (value < 1) {
            this.onError({
                statusCode: BetSlipErrorCodes.InvalidInputAmount,
                message: BetSlipErrorMessages.InputLessThanOne
            });

            // Set last applied value back
            this[key] = this._memory[key];

            return false;
        }

        return true;
    }

    @action.bound
    private onError(error: INotificationProviderError) {
        this.error = error;

        if (this.config.notificationProvider && this.config.notificationProvider.onError) {
            // we must ensure we do not pass observable here - it is inconvenient to check
            // if observable is an array or not
            this.config.notificationProvider.onError(toJS(this.error));
        }
    }

    /**
     * Used on web
     * Resets error
     */
    resetError() {
        this.error = null;
    }

    /**
     * Used on web
     * On change handler for [bet slip type]{@link BetSlipStore#betSlipType}
     * Will trigger change and processing if different bet slip type is selected.
     * @see BetSlipTypes
     * @param {'single'|'combination'|'system'} value
     */
    async onBetSlipTypeChange(value) {
        let wasSystem = false;
        if (this.betSlipType === BetSlipTypes.SYSTEM) {
            wasSystem = true;
        }

        if (wasSystem) {
            this.selectedCombinations = [];
        }

        if (value !== this.betSlipType) {
            this.betSlipType = value;

            if (this.offerSize > 0) {
                await this.handleProcessBetSlip({ betSlipRequestData: () => this.clickedTipsRequestModel, type: ActionType.USER_ACTION });
            }
        }
    }

    /**
     * Not used on web
     * Enables or disables one click bet. This is handy method for toggling between normal & one click.
     */
    enableDisableOneClickBet() {
        if (this.betSlipBetType === BetSlipBetType.Normal) {
            this.onBetSlipBetTypeChange(BetSlipBetType.OneClickBet)
        }
        else {
            this.onBetSlipBetTypeChange(BetSlipBetType.Normal)
        }
    }

    /**
     * Used on web
     * Deactivates one click bet
     */
    onDeactivateOneClickBet() {
        this.onBetSlipBetTypeChange(BetSlipBetType.Normal);
    }

    /**
     * Used on web
     * [Bet slip bet type]{@link BetSlipStore#betSlipBetType} on change event handler. This is used to switch between normal & one click bet slip.
     * Internally calls reset method each time bet slip bet type is changed.
     * @param {'one-click'|'normal'} value 
     */
    onBetSlipBetTypeChange(value) {
        if (this.betSlipBetType !== value) {
            this.reset({ options: { resetSubmitDelay: false } });
            this.betSlipBetType = value;
        }
    }

    /** Add combination if it is not selected, otherwise removes it. */
    public async addRemoveCombination(key: number) {

        const comboIndex = this.selectedCombinations.indexOf(key);
        if (comboIndex > -1) {
            this.forceSync();
            // @ts-expect-error
            this.selectedCombinations.remove(key);
        }
        else {
            this.selectedCombinations.push(key);
        }

        await this.handleProcessBetSlip({ betSlipRequestData: () => this.clickedTipsRequestModel, type: ActionType.USER_ACTION });

        runInAction(() => {
            if (this.error != null) {
                const plainError = toJS(this.error.error);

                // remove last added combination if one of the two errors occur (as per 74325)
                if (Array.isArray(plainError) && plainError.some(e => [400005, 400022].includes(e.errorCode))) {
                    // @ts-expect-error
                    this.selectedCombinations.remove(key);
                }
            }

            const unavailableCombos = this.selectedCombinations.filter(c => this.availableCombinations.findIndex(ac => ac.key === c) === -1);

            unavailableCombos.forEach(c => {
                // @ts-expect-error
                this.selectedCombinations.remove(c);
            });

        });
    }

    /** Adds offer as bank in system slip. */
    public async addRemoveBank(eventId: string) {

        if (this.bankEvents.includes(eventId)) {
            this.forceSync();
            // @ts-expect-error
            this.bankEvents.remove(eventId);
        }
        else {
            this.bankEvents.push(eventId)
        }

        this.updateClickedTipBankValues();

        await this.handleProcessBetSlip({
            betSlipRequestData: () => this.clickedTipsRequestModel,
            type: ActionType.USER_ACTION
        });
    }

    /** Not used on web */
    updateClickedTipBankValues() {
        this.clickedTips = this.clickedTips.map(ct => ({ ...ct, isBank: this.bankEvents.includes(ct.eventId) }));
    }

    /** Not used on web */
    recheckBankEventsWithClickedOffer() {
        const setOfClickedEventIds = new Set(this.clickedTips.map(ct => ct.eventId));
        this.bankEvents = this.bankEvents.filter(bank => setOfClickedEventIds.has(bank));
    }

    /** Add/remove offer to/from slip. If one click bet is active, submit is immediately called. */
    async addRemoveOffer(tip: Omit<IBaseTip, 'isBank'> & { isBank: boolean }, currentAccountType = null) {
        if (this.isOneClickBetActive) {
            await this.submitOneClickBet(tip, currentAccountType);
            return;
        }

        if (!this.isTipClicked(tip.id)) {
            await this.addToBetSlip(tip);
        } else {
            this.removeFromBetSlip(tip);
        }
    }

    /** Add/remove offer to/from slip. If one click bet is active, submit is immediately called. */
    public async addRemoveOfferWithTypeChange(tip: Omit<IBaseTip, 'isBank'> & { isBank: boolean }, currentAccountType = null) {
        if (this.isOneClickBetActive) {
            await this.submitOneClickBet(tip, currentAccountType);
            return;
        }

        if (!this.isTipClicked(tip.id)) {
            this.checkPaymentType(tip);
            await this.addToBetSlip(tip);
        } else {
            this.checkPaymentType(tip, true);
            await this.removeFromBetSlip(tip);
        }
    }

    /** Adds tip to the slip and submits to server. */
    @action.bound
    private async addToBetSlip(tip: IBaseTip) {
        tip.isBank = this.bankEvents.includes(tip.eventId);

        this.clickedTips = [...this.clickedTips, mapRequestOffer(tip)];

        await this.handleProcessBetSlip({ betSlipRequestData: () => this.clickedTipsRequestModel, type: ActionType.USER_ACTION });

    }

    /** Not used on web */
    async replaceOffers(newOffers, immediateAction) {
        if (newOffers && newOffers.length > 0) {
            this.clickedTips = newOffers.map(mapRequestOffer);
            this.bankEvents = uniq(newOffers.filter(o => o.isBank).map(o => o.eventId));

            const type = immediateAction ? ActionType.IMMEDIATE_ACTION : ActionType.USER_ACTION;

            await this.handleProcessBetSlip({ betSlipRequestData: () => this.clickedTipsRequestModel, type });

        }
    }

    @action.bound
    private updateClickedTipValues(updatedOffers: BetSlipOffer[]) {
        const updatedOffersMap = new Map(updatedOffers.map(offer => [offer.bettingOfferId, offer]));
        this.clickedTips.forEach(ct => {
            if (updatedOffersMap.has(ct.id)) {
                ct.value = updatedOffersMap.get(ct.id).value;
            }
        });
    }

    /** Removes tip from bet slip. */
    public async removeFromBetSlip(tip: { id: string }) {

        if (Array.isArray(tip)) {
            this.clickedTips = this.clickedTips.filter(ct => !tip.includes(ct.id));
        } else { //single by tip object
            const index = this.clickedTips.findIndex(ct => ct.id === tip.id);
            if (index == -1) {
                console.error("Cant remove tip, not found");
                return;
            }

            this.clickedTips.splice(index, 1);
        }

        this.recheckBankEventsWithClickedOffer();

        if (this.clickedTips.length === 0) {
            this.reset();
            return;
        }

        await this.handleProcessBetSlip({ betSlipRequestData: () => this.clickedTipsRequestModel, type: ActionType.USER_ACTION });
    }

    @action.bound
    private forceSync() {
        this._forceSync = true;
    }

    private resetForceSync() {
        this._forceSync = false;
    }

    /** Toggles tip isExcluded state. Internally calls processing. */
    public async toggleTipExclude(tipId: string) {
        if (tipId == null) return;

        const offer = this.slip.betSlipOffers.find(o => o.bettingOfferId == tipId);
        if (offer == null) return;

        offer.isExcluded = !offer.isExcluded;

        this.updateClickedTipExcludeValues(tipId, offer.isExcluded);

        try {
            this.forceSync();
            await this.handleProcessBetSlip({
                betSlipRequestData: this.currentSlipOffers,
                action: BetSlipActions.Validate,
                type: ActionType.USER_ACTION,
            });
        } catch (err) {
            runInAction(() => {
                console.error(err);
                offer.isExcluded = !offer.isExcluded;
            })
        }
    }

    updateClickedTipExcludeValues(tipId: string, isExcluded: boolean) {
        const clickedTipIndex = this.clickedTips.findIndex(ct => ct.id === tipId);
        runInAction(() => {
            this.clickedTips[clickedTipIndex].isExcluded = isExcluded;
        })
    }


    /** Resets bet slip state to initial state. */
    public reset(params: { resetSubmitDelay?: boolean, forceUnsubscribe?: boolean, options?: unknown, leavePayment?: boolean } = {}) {

        const { forceUnsubscribe = true, options = null, leavePayment = false } = params

        if (forceUnsubscribe) {
            this.disposeHubSubscription();
        }

        let _internalOptions = {
            resetSubmitDelay: true
        };

        if (options) {
            Object.assign(_internalOptions, options);
        }

        this.slip = createEmptySlip();

        this.submitted = false;

        this.isReuseOn = false;

        this.error = null;

        this.betSlipType = this.initialState.betSlipType;

        this.onResetSystemSlipData();

        this.totalStake = this.initialState.totalStake;

        if (!leavePayment) {
            this.payment = this.initialState.totalStake;
        }

        this.stakePerCombination = this.initialState.stakePerCombination;
        this.paymentPerCombination = this.initialState.paymentPerCombination;
        this.combinationBettingOffersCount = 0;
        this.oneClickBetAmount = 2;

        this.bankEvents = [];

        // handling fees
        this.handlingFeePercentage = 0;
        this.handlingFeeAmount = 0;

        // gain and gain bonuses
        this.maxGain = 0;
        this.minGain = 0;
        this.gain = this.initialState.gain;
        this.gainBonusAmount = 0;
        this.gainBonusPercentage = 0;
        this.gainTaxAmount = 0;
        this.gainTaxPercentage = 0;

        this.eventCount = 0;

        this._memory = createMemory(this);

        this.combinations = 0;
        this.paymentType = this.initialState.paymentType;
        this.isBettingBlocked = false;
        this.acceptOddsChanges = false;
        this.betSlipBetType = BetSlipBetType.Normal;

        if (_internalOptions.resetSubmitDelay) {
            this.setSubmitDelay(null);
        }

        this.isSubmitting = false;
        this.isProcessing = false;

        this.clickedTips = [];

        this.actionController.reset();

        this.resetForceSync();
        this.config.onReset();
    }

    /**
     * @private
     * Resets system slip data.
     * 
     * Resets:
     *  - available combinations
     *  - selected combinations
     *  - bank events
     */
    onResetSystemSlipData() {
        this.availableCombinations = [];
        this.selectedCombinations = [];
    }

    private async _sendRequest<T extends (...args: any) => any>(requestFn: T): Promise<ReturnType<T>> {
        let result = null;

        try {
            const response = await requestFn();
            if (response && Array.isArray(response) && response.length > 0) {
                result = response;
            } else if (response && typeof response === 'object') {
                result = [response];
            } else {
                this.onError({
                    statusCode: BetSlipErrorCodes.ServerError,
                    message: BetSlipErrorMessages.ServerError,
                });

                //this.reset();
            }
        }
        catch (err) {
            console.error(err)
            if (err.code === 20) {
                return;
            }

            if (err.statusCode === 0) {
                this.config.setServerAvailability(false);
            }

            if (err.statusCode === 400 && err.data && err.data.length > 0) {
                result = err.data;
            }
            else {
                this.onError({
                    statusCode: BetSlipErrorCodes.ServerError,
                    message: BetSlipErrorMessages.ServerError,
                    error: err
                });

                //this.reset();
            }
        }

        if (result && result.length === 1) {
            return result[0];
        }
    }

    public setSubmitDelay(value: number) {
        if (this.submitDelay === value) return
        this.submitDelay = value;
    }

    @action.bound
    private async handleProcessBetSlip(processArguments: PartialBy<Parameters<BetSlipStore['processBetSlip']>[0], "action"> & Parameters<BetSlipStore['actionController']['handleNewAction']>[0]) {

        const { action = BetSlipActions.Validate, isUse } = processArguments;

        if (isUse || action !== BetSlipActions.Validate) {
            await this.processBetSlip(processArguments);
            this.actionController.reset();
            return;
        }

        this.actionController.handleNewAction(processArguments);
    }

    /**
     * This method sends bet slip to the API using provided service. 
     * Depending on the action parameter, you can either validate
     * or submit bet slip.
     * 
     * In case of an exception, it is attached to an error
     */
    @action.bound
    private async processBetSlip(processArguments: {
        action?: BetSlipActions,
        options?: {
            removeInactiveOffers?: boolean;
            acceptOddsChanges?: boolean;
            activeAccount?: string | null;
        },
        isUse?: boolean,
        betSlipRequestData: BetSlipRequestData | (() => BetSlipRequestData)
    } & Parameters<BetSlipStore['actionController']['resolveAction']>[0]) {

        const { action = BetSlipActions.Validate, options = null, isUse = false } = processArguments;

        let { betSlipRequestData } = processArguments;

        // get current slip offer values
        // if there was scheduled action with not the latest offer values 
        // value indicators will go nuts
        if (typeof betSlipRequestData === 'function') {
            betSlipRequestData = betSlipRequestData();
        }

        this.submitted = false;

        const isValidate = action === BetSlipActions.Validate;

        const isPreprocessedRequest = 'betSlipsFromRequest' in betSlipRequestData && !!betSlipRequestData.betSlipsFromRequest;

        let removeInactiveOffers = false;
        let optionAcceptOddsChanges = null;
        if (options != null) {
            removeInactiveOffers = options.removeInactiveOffers || false;
            optionAcceptOddsChanges = options.acceptOddsChanges || null;
        }

        if (
            action !== BetSlipActions.Validate &&
            action !== BetSlipActions.Submit &&
            action !== BetSlipActions.Test &&
            action !== BetSlipActions.SendRequest &&
            action !== BetSlipActions.Approved
        ) {
            console.error("Wrong action", action);
            return;
        }

        /**
         * NOTE: slip request can only be aborted when slip is not submitting!
         * This is because if slip contains live events, countdown can appear and in case of slip background sync and validate
         * we don't want to cancel submit
         */
        if (this.isProcessing && !this.isSubmitting) {
            this.abortController.abort();
            this.abortController = new AbortController();
        }
        else if (this.isProcessing) {
            // if slip is processing (validate, submit or whatever), we don't want to initiate another processing request!
            return;
        }

        if (this.betSlipType === BetSlipTypes.SYSTEM && !isPreprocessedRequest && Array.isArray(betSlipRequestData)) {
            // this essentially finds all non banked events and offers from same events and removes them
            // this is because bank is tracked per event and not per offer and in order to calculate correct
            // system combination we need to remove remove duplicate events (rule is, if offer from event is banked, all other offers from same event are banked)
            // I can't... even... bother
            const comboSource = betSlipRequestData
                .filter((d, idx, src) =>
                    !d.isBank &&
                    src.findIndex(s => s.eventId === d.eventId) === idx
                );

            // if we selected combinations 6, but we have only 5 non-banked events
            // we need to remove combination 6 because it violates available combinations limit
            // put in more simple words: you cannot add 6/5 combination because it makes no sense
            if (comboSource.length <= max(this.selectedCombinations)) {
                this.selectedCombinations = this.selectedCombinations.filter(i => i <= comboSource.length);
            }
        }

        let requestPayload: IBetSlipRequest | null = null

        if (isPreprocessedRequest && !Array.isArray(betSlipRequestData)) {
            requestPayload = betSlipRequestData
        } else if (Array.isArray(betSlipRequestData)) {
            requestPayload = {
                acceptOddsChanges: optionAcceptOddsChanges !== null ? optionAcceptOddsChanges : this.acceptOddsChanges,
                bettingAccountTypeId: options?.activeAccount || null,
                betSlipsFromRequest: [{
                    payment: this.slipPaymentAmount,
                    paymentType: this.paymentType,
                    type: this.betSlipType,
                    system: this.formattedCombinations,
                    offers: betSlipRequestData
                }]
            };
        } else {
            console.error("Could not create bet slip request payload. Got: ", betSlipRequestData)
        }

        const slipRequest = requestPayload.betSlipsFromRequest[0];

        // empty offer, don't process
        if (slipRequest.offers.length < 1) {
            return;
        }

        // reset error to null
        this.error = null;

        this.isProcessing = true;

        try {
            const response = await this._sendRequest(() => {
                if (action === BetSlipActions.Submit) {
                    this.isSubmitting = true;
                    return this.betSlipService.submit(
                        requestPayload,
                        {
                            signal: this.abortController.signal
                        }
                    )
                } else if (action === BetSlipActions.Test) {
                    this.isSubmitting = true;
                    return this.betSlipService.test(
                        requestPayload,
                        {
                            signal: this.abortController.signal
                        }
                    )
                } else if (action === BetSlipActions.SendRequest) {
                    this.isSubmitting = true
                    return this.betSlipService.requestPayment(
                        requestPayload,
                        {
                            signal: this.abortController.signal
                        }
                    )
                } else if (action === BetSlipActions.Approved) {
                    return this.betSlipService.approvedSubmit(
                        requestPayload,
                        {
                            signal: this.abortController.signal
                        }
                    )
                } else {
                    return this.betSlipService.validate(
                        requestPayload,
                        {
                            signal: this.abortController.signal
                        }
                    )
                }
            });

            if (isValidate && !isUse) {
                if (this.isSubmitting) return;

                const shouldResolveResponse = this.actionController.resolveAction(processArguments);

                if (!shouldResolveResponse) return;
            }

            let continueAction;
            let errors;
            
            if (response && !response.betSlip) {
                errors = this.processBetSlipErrors(response);
            } else if (response) {
                runInAction(() => {
                    if (this.betSlipType === BetSlipTypes.SYSTEM) {
                        if (
                            response.validationErrors &&
                            !response.validationErrors
                                .some(item => item.errorCode === 400005 || item.errorCode === 400022) &&
                            response.betSlipProcessingResponses &&
                            !response.betSlipProcessingResponses
                                .some(item => item.errorCode === 400005 || item.errorCode === 400022)
                        ) {
                            this.availableCombinations = response.availableCombinations || [];
                        }
                    }

                    if (response.betSlip?.isLive) {
                        // this will exist only if slip has a live event
                        if (response.submitLiveBetSlipDelayTimeRespones) {
                            this.submitDelay = response.submitLiveBetSlipDelayTimeRespones[0].value;
                        } else {
                            this.submitDelay = null;
                        }
                    }

                    if (removeInactiveOffers) {
                        this.slip.betSlipOffers = [];
                    }

                    // This will fill `this.slip`
                    errors = this.mapSlipResponse(response, isUse, action);

                    if (
                        removeInactiveOffers &&
                        action === BetSlipActions.Validate &&
                        this.slip.betSlipOffers.some(o => o.sportData.isEventDeleted || o.isLocked)
                    ) {
                        this.slip.betSlipOffers = this.slip.betSlipOffers.filter(o => !o.isLocked);
                        const activeOffers = this.slip.betSlipOffers
                            .filter((o) => !o.isExcluded).map(mapCurrentOffer);
                        if (activeOffers.length > 0) {
                            this.clickedTips = activeOffers;
                            const availableEventsForCombination = uniq(
                                activeOffers
                                    .filter(o => !o.isBank)
                                    .map(o => o.eventId)
                            ).length;
                            this.bankEvents = uniq(
                                activeOffers
                                    .filter(o => o.isBank)
                                    .map(o => o.eventId)
                            );
                            if (availableEventsForCombination > 1) {
                                this.selectedCombinations = this.selectedCombinations
                                    .filter(c => c <= availableEventsForCombination);
                                if (
                                    this.selectedCombinations.length == 1 &&
                                    this.selectedCombinations[0] == availableEventsForCombination
                                ) {
                                    this.selectedCombinations = [];
                                }
                            } else {
                                this.selectedCombinations = [];
                            }
                            continueAction = () => this.processBetSlip({
                                ...processArguments,
                                betSlipRequestData: activeOffers
                            });
                        } else {
                            const currentSlip = this.slip;
                            this.reset();
                            this.totalStake = currentSlip.stake;
                            this.slip.stake = currentSlip.stake;
                        }
                    }
                    else {
                        if (
                            action === BetSlipActions.Submit ||
                            action === BetSlipActions.SendRequest ||
                            action === BetSlipActions.Approved
                        ) {
                            if (!errors || errors.length === 0) {
                                if (this.config.updateBalance) {
                                    this.config.updateBalance(response.betSlip?.payment);
                                }

                                if (this.config.notificationProvider.onSuccess) {
                                    this.config.notificationProvider.onSuccess({ action, id: response.betSlip?.id ||"", betSlipNumber: response.betSlip?.betSlipNumber });
                                }
                            }
                        }

                        if (action === BetSlipActions.Test) {
                            if (!errors || errors.length === 0) {
                                if (this.config.notificationProvider.onSuccessTest) {
                                    this.config.notificationProvider.onSuccessTest();
                                }
                            }
                        }
                    }
                });
            }

            if (continueAction) {
                await continueAction();
            } else {
                if (errors && errors.length > 0) {
                    this.onError({
                        statusCode: BetSlipErrorCodes.ValidationError,
                        message: BetSlipErrorMessages.ValidationError,
                        error: errors,
                        mappedError: this._mapErrors(errors, this.slip.betSlipOffers),
                        action,
                    });
                }

                runInAction(() => {
                    this.isProcessing = false;
                    this.resetForceSync();
                });
            }
        }
        catch (err) {
            console.error(err);
            runInAction(() => {
                this.isProcessing = false;

                this.resetForceSync();
            });
        }
    }



    /**
     * Used on web
     * Submits bet slip to the server. If submit fails, exception is attached to the error.
     * Upon success, reset method is called, otherwise if test is enabled then callback is triggered.
     * 
     * Requires user to be logged in. If user is not logged in, error message is attached to the error
     * Error message: "BET_SLIP.ERRORS.USER_NOT_AUTHENTICATED"
     */
    async submitSlip(isTest = false, isPaymentRequest = false, isApproved = false, currentAccountType: string | null = null) {
        // prevents multiple submits
        if (this.isSubmitting || this.isSubmitDisabled) {
            return;
        }

        if (this.isProcessingSlipEmpty) {
            this.onError({
                statusCode: BetSlipErrorCodes.SlipEmpty,
                message: BetSlipErrorMessages.SlipEmpty
            });

            return;
        }

        if (this.config.isUserAuthenticated()) {
            this.isSubmitting = true;
            this.submitted = false;
            this.isReuseOn = false;

            const action = (() => {
                if (isPaymentRequest) return BetSlipActions.SendRequest;
                if (isTest) return BetSlipActions.Test;
                if (isApproved) return BetSlipActions.Approved;
                return BetSlipActions.Submit;
            })();

            await this.processBetSlip({
                betSlipRequestData: this.currentSlipOffers,
                action,
                options: {
                    activeAccount: currentAccountType,
                }
            });

            if (!isTest && !this.hasErrors) {
                runInAction(() => {
                    this.submitted = true;
                });
                if (!this.config.isReuseEnabled) {
                    this.reset();
                }
            }

            runInAction(() => {
                this.isSubmitting = false;
            });

        } else {
            this.onError({
                statusCode: BetSlipErrorCodes.Unauthenticated,
                message: BetSlipErrorMessages.Unauthenticated
            });
        }
    }

    /** @description Process current bet slip state. This can be used to process initial state of bet slip. */
    public async processCurrentBetSlipState() {
        if (this.isSlipEmpty) return;

        await this.handleProcessBetSlip({
            betSlipRequestData: () => this.clickedTipsRequestModel,
            type: ActionType.USER_ACTION
        });
    }

    private _mapErrors = (
        errors: (BetSlipValidationError | BetSlipProcessingError | BetSlipUserValidationOnBetSlipSubmitError)[],
        offers: BetSlipOffer[]
    ): INotificationProviderError['mappedError'] => {
        const output = uniqWith(errors, ((a, o) => a.errorCode === o.errorCode && a.eventId === o.eventId && a.bettingOfferKeyId === o.bettingOfferKeyId && a.bettingOfferId === o.bettingOfferId && a.correlationId === o.correlationId)).map(e => {
            const m: (BetSlipValidationError | BetSlipProcessingError | BetSlipUserValidationOnBetSlipSubmitError) = {
                ...e,
                event: null,
                sportData: e.sportData || ([] as BetSlipOffer[]),
            };

            // if correlationId exists, it means error is related to specific offer
            // correlationId = bettingOfferId
            if (
                (e.correlationId != null && e.correlationId !== SGuid.empty) ||
                e.bettingOfferId != null
            ) {
                const compareId = e.correlationId || e.bettingOfferId;
                const offer = offers.find(o => o.bettingOfferId === compareId);
                if (offer != null) m.sportData = [offer];

                return m;
            }

            // error with entire offer key
            if (e.bettingOfferKeyId != null) {
                const keyOffers = offers.filter(o => o.bettingOfferKeyId === e.bettingOfferKeyId);
                if (keyOffers != null) m.sportData = keyOffers;

                return m;
            }

            // error with entire event
            if (e.eventId != null) {
                // only event info!
                const eventOffers = offers.filter(o => o.eventId === e.eventId);
                m.event = eventOffers[0].sportData;
                if (eventOffers != null) m.sportData = eventOffers;

                return m;
            }

            return m;
        });

        return output;
    }


    /** Adds tip and submits bet slip. */
    private async submitOneClickBet(
        tip: Omit<IBaseTip, "isBank"> & { isBank: boolean },
        currentAccountType = null
    ) {
        if (!this.config.isUserAuthenticated()) {
            this.onError({
                statusCode: BetSlipErrorCodes.Unauthenticated,
                message: BetSlipErrorMessages.Unauthenticated
            });
            return;
        }

        if (this.onClickInProgress) {
            return;
        }

        this.onClickInProgress = true;

        this.error = null;

        this.isProcessing = true;

        this._oneClickTips.push(tip.id);

        let requestPayload: IBetSlipRequest = {
            acceptOddsChanges: true,
            bettingAccountTypeId: currentAccountType,
            betSlipsFromRequest: [{
                payment: this.slipPaymentAmount,
                paymentType: this.paymentType,
                type: this.betSlipType,
                offers: [tip]
            }]
        };

        try {
            this.isSubmitting = true;
            this.submitted = false;
            const response = await this._sendRequest(() => this.betSlipService.submit(requestPayload));

            if (response) {
                const errors = this.processBetSlipErrors(response);

                // ----- This is the part that is different than in original utils function
                const { ...slip } = response.betSlip;
                Object.assign(this.slip, slip)
                // -------------------------------------------------

                if (!errors || errors.length === 0) {
                    if (this.config.updateBalance) {
                        this.config.updateBalance(response.betSlip?.payment);
                    }

                    if (this.config.notificationProvider.onSuccess) {
                        this.config.notificationProvider.onSuccess({ action: BetSlipActions.Submit, id: this.slip.id, betSlipNumber: this.slip.betSlipNumber });
                    }
                }
                else {
                    this.onError({
                        statusCode: BetSlipErrorCodes.ValidationError,
                        message: BetSlipErrorMessages.ValidationError,
                        error: errors,
                        offers: response.betSlip?.betSlipOffers,
                        mappedError: this._mapErrors(errors, response.betSlip?.betSlipOffers ||[]),
                        action: BetSlipActions.Submit,
                    });
                }
            }
        }
        finally {
            runInAction(() => {
                setTimeout(() => {
                    // @ts-expect-error
                    this._oneClickTips.remove(tip.id);
                    this.slip = createEmptySlip();
                    this.isProcessing = false;
                    this.isSubmitting = false;
                    this.onClickInProgress = false;
                }, this.config.oneClickBetShowDuration);
            });
        }
    }

    //#endregion actions

    //#region utility

    /** Checks if the tip with id tipId is in the slip. */
    public isInBetSlip(tipId: string) {
        return this.slip.betSlipOffers.find(o => o.bettingOfferId == tipId) != undefined;
    }

    /** Checks if the tip with id tipId is clicked. */
    public isTipClicked(tipId: string) {
        return !!this.clickedTips.find(t => t.id == tipId);
    }

    /** Checks if the tip with id tipId was clicked in one click bet mode. */
    public isInOneClickBet(tipId: string) {
        return this._oneClickTips.includes(tipId);
    }

    private mapSlipResponse(response: BetSlipResponse, isUse, action) {
        const slip = response.betSlip

        if (action === BetSlipActions.Submit) {
            this.offersFromLustSubmit = { ...this.currentSlipOffers };
        }

        slip.betSlipOffers.forEach(x => {
            const currentOffer = this.slip.betSlipOffers.find(o => x.bettingOfferId == o.bettingOfferId);

            x.isExcluded = currentOffer?.isExcluded;
            x.eventResultData = currentOffer != null && currentOffer.eventResultData ?
                currentOffer.eventResultData :
                null;
            x.matchTime = currentOffer != null && currentOffer.matchTime ?
                currentOffer.matchTime
                : null;
        });

        slip.betSlipOffers = [
            ...slip.betSlipOffers,
            // Add excluded offers back to slip
            ...this.slip.betSlipOffers.filter(
                oldOffer =>
                    oldOffer.isExcluded &&
                    this.clickedTips.some(ct => ct.id === oldOffer.bettingOfferId) &&
                    !slip.betSlipOffers.some(newOffer => newOffer.bettingOfferId === oldOffer.bettingOfferId)
            )
        ]

        slip.betSlipOffers
            .sort((x, y) => {
                //forces same order on slip
                //example: 
                //      offer in live is canceled it will go on bottom of slip
                //      server return locked offers latest
                return (
                    this.clickedTips.findIndex(ct => ct.id === x.bettingOfferId)
                    -
                    this.clickedTips.findIndex(ct => ct.id === y.bettingOfferId))
            });

        Object.assign(this.slip, slip);

        if (isUse) {
            this.clickedTips = slip.betSlipOffers.map(mapCurrentOffer);
        }

        const groupedOffers = groupBy(this.slip.betSlipOffers, item => item.eventId);
        var currentOffers = [];
        each(groupedOffers, innerList => {
            currentOffers = currentOffers.concat(innerList);
        });

        mapPlayerToSpecifier(currentOffers);
        mapDisplayValues(currentOffers, this.config.offerDisplay);

        this.slip.betSlipOffers = currentOffers;

        this.combinations = slip.totalNumberOfCombinations;
        this.stakePerCombination = slip.stakePerCombination;
        this.paymentPerCombination = slip.paymentPerCombination;
        this.totalStake = slip.payment;
        this.combinationBettingOffersCount = slip.combinationBettingOffersCount;
        this.payment = slip.payment;
        this.isRequestForPaymentEnabled = response.isRequestForPaymentEnabled;

        this.slip.bankOfferCount = slip.combinationBettingOffersBankCount;

        // handling fee
        this.handlingFeeAmount = slip.handlingFee;
        this.handlingFeePercentage = slip.handlingFeePercentage;

        // gain and gain bonuses
        this.maxGain = slip.maxGain;
        this.minGain = slip.minGain;
        this.gain = slip.gain;
        this.gainBonusAmount = slip.gainBonus;
        this.gainBonusPercentage = slip.gainBonusPercentage;
        this.gainTaxAmount = slip.gainTax;
        this.gainTaxPercentage = slip.gainTaxPercentage;

        this.eventCount = slip.eventCount;

        const errors = [
            ...this.processPremapBetSlipErrors(response),
            ...this.processBetSlipErrors(response)
        ];

        /**
         * NOTE: this MUST be done AFTER this.processBetSlipErrors because that method
         * updates indicators as well
         */
        const offersWithIndicators = this.slip.betSlipOffers.filter(o => o.indicator !== 0);
        if (offersWithIndicators.length > 0) {
            clearTimeout(this.bsIndicatorsTimeoutId);

            this.updateClickedTipValues(offersWithIndicators);

            this.bsIndicatorsTimeoutId = setTimeout(() => {
                runInAction(() => {
                    offersWithIndicators.forEach(o => {
                        o.indicator = 0;
                    });
                });
            }, this.config.slipIndicatorDuration);
        }

        const containsStakeError = errors
            .find(errorItem => stakeErrorCodes.includes(errorItem.errorCode)) !== undefined;

        if (this._forceSync || !this.isChangingSlipValues || !containsStakeError) {
            this._memory = createMemory(this);
        }

        return errors;
    }

    private bsIndicatorsTimeoutId: NodeJS.Timeout | undefined = undefined
    private processPremapBetSlipErrors(data: BetSlipResponse) {
        let result = [];

        const validationErrors = data.validationErrors;
        const processingErrors = data.betSlipProcessingResponses;

        if (processingErrors && processingErrors.length > 0) {
            processingErrors
                .filter(e => [400005, 400022, 400098].includes(e.errorCode))
                .forEach(error => {
                    result.push(error);
                })
        }
        if (validationErrors && validationErrors.length > 0) {
            validationErrors
                .filter(e => [4000011, 400044, 400021, 400022, 400039].includes(e.errorCode))
                .forEach(error => {
                    if (error.errorCode === 400039) {
                        const bettingTypeOne = data.betSlip?.betSlipOffers
                            .find(offer => offer.bettingOfferId === error.correlationId)
                        const bettingTypeTwo = data.betSlip?.betSlipOffers
                            .find(offer => offer.bettingOfferId === error.correlationTwoId)

                        if (bettingTypeOne != null && bettingTypeTwo != null) {
                            const btOneData = bettingTypeOne.sportData;
                            const btTwoData = bettingTypeTwo.sportData;
                            if (btOneData != null && btTwoData != null) {
                                error.bettingTypeOne = btOneData.bettingTypeNameForBettingSlip;
                                error.bettingTypeTwo = btTwoData.bettingTypeNameForBettingSlip;
                            }
                        }
                    }
                    if (error.errorCode === 400044) {
                        const offerInResponse = data.betSlip?.betSlipOffers
                            .find(offer => offer.eventId === error.eventId);

                        if (offerInResponse && offerInResponse.sportData) {
                            error.sportData = [offerInResponse];
                        }

                        each(this.slip.betSlipOffers, slipItem => {
                            if (slipItem.eventId === error.eventId) {
                                slipItem.errors = [error];
                            }
                        });
                    }

                    result.push(error);
                });
        }

        return result;
    }

    @action.bound
    private processBetSlipErrors(data: BetSlipResponse) {
        let result: (BetSlipValidationError | BetSlipProcessingError | BetSlipUserValidationOnBetSlipSubmitError)[] = [];
        const validationErrors = data.validationErrors;
        const processingErrors = data.betSlipProcessingResponses;
        const userValidationOnBetSlipSubmitErrors = data.userValidationOnBetSlipSubmitResponses;

        for (const offer of this.slip.betSlipOffers) {
            offer.errors = null;
        }
        const offers = this.slip.betSlipOffers

        const processOfferError = (offer, error) => {
            offer.errors = offer.errors || [];
            offer.errors.push(error);
        };

        if (validationErrors && validationErrors.length > 0) {
            validationErrors.forEach(error => {
                if (
                    (stakeErrorCodes.includes(error.errorCode) && this.isChangingSlipValues) ||
                    this.isSubmitting
                ) {
                    result.push(error);
                }

                const betSlipOffers: BetSlipOffer[] =
                    (
                        // @ts-expect-error @todo this should never happen
                        data?.slip?.betSlipOffers
                    ) ||
                    (
                        data?.betSlip?.betSlipOffers
                    )

                if (
                    betSlipOffers != null &&
                    [4000011, 400012, 400014, 400043].includes(error.errorCode)
                ) {
                    const relatedOffer = betSlipOffers
                        .find(item => item.bettingOfferId === error.correlationId);
                    if (relatedOffer) {
                        error.sportData = [relatedOffer];
                    }
                    result.push(error);
                }

                offers
                    .filter(o => o.bettingOfferId === error.correlationId)
                    .forEach(offer => {
                        if (!offer.errors) {
                            offer.errors = [];
                        }
                        offer.errors.push(error);
                        result.push(error);
                    });
            });
        }

        if (processingErrors && processingErrors.length > 0) {
            processingErrors.forEach(error => {
                if (
                    !this.isSubmitting &&
                    (error.errorCode === 400001 || error.errorCode === 400010)
                ) {
                    // invalid quota - don't include in error list, set indicator only
                    let offer = offers.find(o => o.bettingOfferId === error.bettingOfferId);
                    if (
                        offer &&
                        error.quotaValueDiffIndicator &&
                        error.quotaValueDiffIndicator !== 0
                    ) {
                        offer.indicator = error.quotaValueDiffIndicator;
                    }
                    return
                }

                result.push(error);
                switch (error.errorCode) {
                    case 400004: // event not anymore in offer or in incorrect state
                    case 400002: // unknown team
                    case 400014: // The events not in offer
                        offers
                            .filter(o => error.eventId === o.eventId)
                            .forEach(offer => {
                                offer.isDeleted = true;
                                offer.sportData.isEventDeleted = true;
                                offer.isEventDeleted = true;
                                processOfferError(offer, error);
                            });
                        break;
                    case 400003: // offer not anymore in offer
                    case 400011: // The betting types not in offer
                    case 400012: // The betting type tips not in offer
                        offers.filter(o => (
                            o.bettingOfferId === error.bettingOfferId ||
                            o.bettingOfferKeyId === error.bettingOfferKeyId ||
                            o.eventId === error.eventId
                        ))
                            .forEach(x => {
                                x.isDeleted = true;
                                processOfferError(x, error);
                            });
                        break;
                    case 400062: // The betting is stopped for selected event
                        offers.filter(o => error.eventId === o.eventId)
                            .forEach(offer => {
                                offer.isLocked = true;
                                offer.isEventLocked = true;
                                processOfferError(offer, error);
                            });
                        break;
                    case 400063: // The betting is stopped for selected offer
                        offers.filter(o => (
                            o.bettingOfferId === error.bettingOfferId ||
                            o.bettingOfferKeyId === error.bettingOfferKeyId ||
                            o.eventId === error.eventId
                        ))
                            .forEach(x => {
                                x.isLocked = true;
                                x.isOfferLocked = true;
                                processOfferError(x, error);
                            });
                        break;
                    default:
                        const filterFn = error.bettingOfferId
                            ? o => error.bettingOfferId === o.bettingOfferId
                            : o => error.eventId === o.eventId;
                        offers
                            .filter(filterFn)
                            .forEach(i => processOfferError(i, error));
                        break;
                }
            });
        }

        if (
            userValidationOnBetSlipSubmitErrors &&
            userValidationOnBetSlipSubmitErrors.length > 0
        ) {
            userValidationOnBetSlipSubmitErrors.forEach(error => {
                result.push(error);
            })
        }

        return result;
    }

    /** Not used on web */
    async revalidateSlip() {
        await this.handleProcessBetSlip({
            betSlipRequestData: () => this.clickedTipsRequestModel,
            type: ActionType.IMMEDIATE_ACTION
        });
    }

    //#endregion utility

    //#region disposer

    public onDispose() {
        this.excludedEvents.clear();
        this.disposeHubSubscription();
        this.disposeRunAutoRunDisposer();
    }

    @action.bound
    private disposeRunAutoRunDisposer() {
        if (this.runAutoRunDisposer != null) {
            this.runAutoRunDisposer();
            this.runAutoRunDisposer = null;
        }
    }

    //#endregion disposer
}

decorate(BetSlipStore, {
    payment: observable,
    totalStake: observable,
    combinationBettingOffersCount: observable,
    acceptOddsChanges: observable,
    betSlipBetType: observable,
    _oneClickTips: observable,
    combinations: observable,
    isBettingBlocked: observable,
    stakePerCombination: observable,
    paymentPerCombination: observable,
    gain: observable,
    oneClickBetAmount: observable,
    betSlipType: observable,
    slip: observable,
    paymentType: observable,
    bankEvents: observable,
    error: observable,
    isSubmitting: observable,
    isProcessing: observable,
    isChangingSlipValues: observable,
    availableCombinations: observable,
    selectedCombinations: observable,
    handlingFeeAmount: observable,
    handlingFeePercentage: observable,
    maxGain: observable,
    minGain: observable,
    gainBonusAmount: observable,
    gainBonusPercentage: observable,
    gainTaxAmount: observable,
    gainTaxPercentage: observable,
    isReuseOn: observable,
    submitted: observable,
    submitDelay: observable,
    betSlipSyncVersion: observable,
    eventCount: observable,
    clickedTips: observable,
    onClickInProgress: observable,
    offersFromLustSubmit: observable,
    addRemoveCombination: action.bound,
    addRemoveOffer: action.bound,
    replaceOffers: action.bound,
    addRemoveBank: action.bound,
    removeFromBetSlip: action.bound,
    removeInactiveOffers: action.bound,
    reset: action.bound,
    useExistingSlip: action.bound,
    onBetSlipBetTypeChange: action.bound,
    enableDisableOneClickBet: action.bound,
    onDeactivateOneClickBet: action.bound,
    onBetSlipTypeChange: action.bound,
    onGainApply: action.bound,
    onGainChange: action.bound,
    onStakePerCombinationApply: action.bound,
    onStakePerCombinationChange: action.bound,
    onPaymentPerCombinationApply: action.bound,
    onPaymentPerCombinationChange: action.bound,
    onToggleAcceptOddsChangesChange: action.bound,
    onTotalStakeApply: action.bound,
    onTotalStakeChange: action.bound,
    submitSlip: action.bound,
    onOneClickBetAmountChange: action.bound,
    resetError: action.bound,
    onResetSystemSlipData: action.bound,
    processCurrentBetSlipState: action.bound,
    revalidateSlip: action.bound,
    recheckBankEventsWithClickedOffer: action.bound,
    onDispose: action.bound,
    toggleTipExclude: action.bound,
    isOneClickBetActive: computed,
    isProcessingSlipEmpty: computed,
    bankOfferCount: computed,
    formattedCombinations: computed,
    slipEventEntries: computed,
    displaySlipEventEntries: computed,
    slipMaxCoefficient: computed,
    slipMaxGain: computed,
    slipOffer: computed,
    slipSize: computed,
    hasErrors: computed,
    isHandlingFeeActive: computed,
});

type BetSlipRequestData =
    IBetSlipRequest
    |
    BetSlipStore['currentSlipOffers']


export {
    BetSlipStore
}