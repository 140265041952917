import { observable, action, decorate, computed } from 'mobx';
import { merge } from 'lodash';

import { convertDecimalToAmerican, convertDecimalToFractional } from '../converters';
import { prepareTipValue } from '../formatters';

import { IOddTypeStoreConfig, IOdd } from '../types';

const DefaultConfig: IOddTypeStoreConfig = {
    defaultOddTypeId: '1',
    cookieKey: 'odd',
    cookieValidity: 365,
    storageProvider: null
}

class OddTypeStore {
    config: IOddTypeStoreConfig = DefaultConfig;
    types: IOdd[] = [
        { id: '1', name: 'ODD_TYPE.DECIMAL' },
        { id: '2', name: 'ODD_TYPE.FRACTIONAL' },
        { id: '3', name: 'ODD_TYPE.AMERICAN' },
    ];

    selectedOddTypeId = this.config.defaultOddTypeId;

    /** Gets selected odd type. */
    get selectedOddType() {
        return this.types.find(i => i.id === this.selectedOddTypeId);
    }

    /**
     * Gets mapping function based on selected odd type
     * Converts from decimal to desired type
     */
    get mapFn(): (value: number) => string{
        if (this.selectedOddTypeId === '2') {
            return (value: number) => convertDecimalToFractional(prepareTipValue(value));
        }
        else if (this.selectedOddTypeId === '3') {
            return (value: number) => convertDecimalToAmerican(prepareTipValue(value));
        }
        else {
            return (value: number) => prepareTipValue(value);
        }
    }

    constructor(config: Partial<IOddTypeStoreConfig> | null = null) {
        if (config) {
            this.config = merge({}, DefaultConfig, config);
        }

        if (config && config.storageProvider) {
            const oddFromCookie = config.storageProvider.get(this.config.cookieKey);

            if (oddFromCookie == null || oddFromCookie === '') {
                // set to cookie
                config.storageProvider.set(this.config.cookieKey, this.selectedOddTypeId, this.config.cookieValidity)
            }
            else {
                this.selectedOddTypeId = oddFromCookie;
            }
        }
    }

    /** Changes selected odd type. */
    onChange(odd: IOdd) {
        if (odd.id !== this.selectedOddTypeId) {
            this.selectedOddTypeId = odd.id;

            if (this.config.storageProvider) {
                this.config.storageProvider.set(this.config.cookieKey, odd.id, this.config.cookieValidity);
            }
        }
    }
}

decorate(OddTypeStore, {
    types: observable,
    selectedOddTypeId: observable,
    mapFn: computed,
    selectedOddType: computed,
    onChange: action.bound
});

export { OddTypeStore };