/**
 * Converts decimal to american odd
 * @param {number} value decimal odd value
 * @returns {string} 
 */
export function convertDecimalToAmerican(value) {
    if (!value) {
        return null;
    }

    if (value >= 2) {
        return Math.round((value - 1) * 100).toString();
    }
    else {
        return Math.round(-100 / (value - 1)).toString();
    }
}